body {
    font-family: 'EB Garamond', sans-serif;
}

.PhoneInputInput {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    border-color: transparent !important;
}

.PhoneInput--focus{
    border: 0;
    border-width: 0px;
    border-color: transparent !important;
}

.PhoneInput--focus {
    border-color: transparent !important;
}

.str-chat__main-panel {
    padding: 0 !important;
}

.str-chat-channel .str-chat__container {
    height: 90% !important;
}

.str-chat.messaging,
.str-chat.commerce {
    background-color: white !important;
}

.str-chat__fileupload-wrapper {
    display: none !important;
}

.container {
    width: 1200px;
    max-width: 90%;
    margin: 0 auto;
}

.grayscale {
    filter: grayscale(10%)
}

.MobileProfile {
    display: none;
}


.my-facebook-button-class {
    width: 100%;
    text-decoration: none;
    display: inline-block;
    border: 1px solid #ced4da;
    background-color: #fff;
    color: #343a40;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0.375rem;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;

    &:hover {
        background-color: #f8f9fa;
        border-color: #cfd8dc;
        color: #212529;
    }

    img {
        width: 1.5rem;
        /* Adjust the width of the image */
        height: 1.5rem;
        /* Adjust the height of the image */
        margin-right: 0.625rem;
        /* Adjust the spacing between the image and text */
    }

    .text-center {
        text-align: center;
    }


}

.calendar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Adjust the opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}