/* Ensure the checkbox is centered properly */
.ant-table-selection-column .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

/* Ensure no overflow from the table cells */
.ant-table-tbody .ant-table-selection-column {
  overflow: hidden;
}

span {
  margin-left: 0;
}
