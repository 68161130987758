ol {
    list-style-type: decimal ;  /* Ensure decimal list style is applied for ordered lists */
    margin-left: 0.5rem;
}

ul {
    list-style-type: none !important;  /* Ensure disc list style is applied for unordered lists */
}
/* Styles for headings within the #accordiandiv */
#accordiandiv h1 {
    font-size: 2.5em !important;
    font-weight: bold;
    margin-bottom: 0.5em;
}

#accordiandiv h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

#accordiandiv h3 {
    font-size: 1.75em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

#accordiandiv h4 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

#accordiandiv h5 {
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

#accordiandiv h6 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

/* Styles for italic text within #accordiandiv */
#accordiandiv em,
#accordiandiv .italic {
    font-style: italic;
}

/* Styles for bold text within #accordiandiv */
#accordiandiv strong,
#accordiandiv .bold {
    font-weight: bold;
}
