/* Customize scrollbar for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  /* Customize scrollbar for Firefox */
  ::-moz-scrollbar {
    width: 6px;
    margin-left: 2px;
  }
  
  ::-moz-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
  
  ::-moz-scrollbar-track {
    background-color: #f1f1f1;
    margin-left: 2px;
  }
  