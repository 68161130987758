@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #ffffff;
    /* Set the default background color */
    color: #000000;
    /* Set the default text color */
}

body.dark {
    background-color: #2c2c2c;
    color: #ffffff;
    /* Set the text color for better visibility */
}

/* Customize the scrollbar for WebKit browsers */
::-webkit-scrollbar {
    margin-top: 3px;
    width: 0px;
    /* Decrease the width */
    height: 3px;
}


::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Customize the thumb color */
    border-radius: 2px;
    /* Round the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Customize the thumb color on hover */
}


.backlogo {
    filter: drop-shadow(10px 4px 30px rgba(0, 0, 0, 0.25));
}
