.datepicker-toggle {
    display: inline-block;
    position: relative;
}

.datepicker-toggle-button {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.datepicker-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
}

.datepicker-input::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
}